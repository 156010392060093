/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import { PageSEO, useSEO } from '../components/SEO';
import Main from '../page-sections/_misc/PrivacyPolicy';

const PrivacyPolicy = ({ location }) => {
  //
  const { pathname } = location;
  const seo = useSEO(pathname);

  return (
    <article>
      <PageSEO
        title={seo.title}
        description={seo.description}
        pathname={pathname}
        featureImage="og-privacy-policy.jpg"
      />
      <Main />
    </article>
  );
};

export default PrivacyPolicy;

PrivacyPolicy.propTypes = {
  location: PropTypes.object.isRequired,
};
